<template>
  <layout-drawer-dialog @close="cancel">
    <v-card flat tile>
      <v-card-title>
        <v-btn
          icon
          class="mr-3"
          @click="cancel"
        >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>
        Link A New Project
      </v-card-title>
      <v-card-text>
        <fire-project-setup
          @saved="$router.push({ name: 'project-index', params: { id: $event } })"
        />
      </v-card-text>
    </v-card>
  </layout-drawer-dialog>
</template>

<script>
export default {
  name: 'NewProject',
  data: () => ({
    loading: false,
    form: {
      name: '',
      identifier: ''
    },
    error: null
  }),
  watch: {
    form: {
      deep: true,
      handler() {
        this.error = null
      }
    }
  },
  methods: {
    cancel() {
      this.$router.push({ name: 'projects' })
    }
  }
}
</script>
